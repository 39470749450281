<template>
  <tr>
    <th class="leftTitle">Staf</th>

    <td
      class="plannerTdStaff"
      :style="{ 'background-color': indexColors[yIndex] }"
      v-for="(y, yIndex) in saveDatas.length"
      :key="yIndex"
    >
      <li
        v-for="(staff, index) in saveDatas[yIndex].jobs[xIndex].team_list"
        :key="index"
        class="mb-25 w-100 text-left"
        :class="{
          errorStaff:
            staffAvailablity(staff.id, saveDatas[yIndex].jobs[xIndex].date) ==
            1,
          warningStaff:
            staffAvailablity(staff.id, saveDatas[yIndex].jobs[xIndex].date) ==
            2,

          wwsuccess: !(
            selectedLiIndex.staffId === staff.id &&
            selectedLiIndex.eventId === saveDatas[yIndex].jobs[xIndex].id
          ),
        }"
        :style="{
          'background-color': saveDatas[yIndex].jobs[xIndex].team_back_colour,
          color: saveDatas[yIndex].jobs[xIndex].team_text_colour,
        }"
        style="cursor: pointer;  transition: all 0.3s ease;"
        @contextmenu.prevent="
          handleClick(
            $event,
            staff.id,
            saveDatas[yIndex].jobs[xIndex],
            staff.status
          )
        "
      >
        <div
          v-if="
            selectedLiIndex.staffId === staff.id &&
              selectedLiIndex.eventId === saveDatas[yIndex].jobs[xIndex].id
          "
        >
          <v-select
            input-id="staffInput"
            style="padding: 0px; margin: 0px; background-color: white; z-index: 9; line-height: 0.8; font-size: 0.875em;   transition: all 0.3s ease; color: rgba(60, 60, 60);"
            :reduce="(val) => val"
            label="full_name"
            v-model="selectedStaffId"
            :options="filteredStaffList"
            :clearable="false"
            class="select-size-sm"
            @input="changeStaff($event, saveDatas[yIndex].jobs[xIndex])"
          >
            <template #option="{ full_name, availabilities, role }">
              <span
                :class="
                  selectDropDownCheck(
                    saveDatas[yIndex].jobs[xIndex].date,
                    availabilities
                  )
                    ? 'bg-danger p-25 text-white'
                    : ''
                "
                v-if="role == 'Foreman'"
                >🤵🏻 {{ full_name }}</span
              >
              <span
                :class="
                  selectDropDownCheck(
                    saveDatas[yIndex].jobs[xIndex].date,
                    availabilities
                  )
                    ? 'bg-danger p-25 text-white'
                    : ''
                "
                v-else
                >🧍🏻 {{ full_name }}</span
              >

              <!-- <cite v-if="selectDropDownCheck(saveDatas[yIndex].jobs[xIndex].date, availabilities)" class="text-danger text-small">
                <br />
                Not Available
              </cite> -->
            </template>
          </v-select>
        </div>

        <div
          @click="eventEmployeeListChange(saveDatas[yIndex].jobs[xIndex])"
          v-else
          @dblclick="staffClick(saveDatas[yIndex].jobs[xIndex].id, staff.id)"
        >
          <span v-if="staff.role == 'Foreman'">🤵🏻</span>
          <span v-else>🧍🏻 </span>
          <span>{{ staff.name }} {{ staff.surname }} </span>
        </div>
      </li>

      <li
        v-if="
          saveDatas[yIndex].jobs[xIndex].employess_required -
            saveDatas[yIndex].jobs[xIndex].actual >
            0 && $Can('staff_modal_add_btn')
        "
        class="mb-25 w-100 text-left transitionEffect"
        v-for="(i, keyIndex) in getRequired(saveDatas[yIndex].jobs[xIndex])"
        v-show="!formShow"
      >
        <v-select
          input-id="staffInput"
          style="padding: 0px; margin: 0px; background-color: white;  line-height: 0.8; font-size: 0.875em;   transition: all 0.3s ease-out; color: rgba(60, 60, 60);"
          :reduce="(val) => val"
          label="full_name"
          v-model="selectedNewStaffId"
          :options="filteredNewStaffList"
          :clearable="false"
          class="select-size-sm"
          @open="
            oldSelectEventClear(
              saveDatas[yIndex].jobs[xIndex].id,
              saveDatas[yIndex].jobs[xIndex].date
            )
          "
          @input="addStaff(2, saveDatas[yIndex].jobs[xIndex])"
        >
          <template #option="{ full_name, availabilities, role }">
            <span
              :class="
                selectDropDownCheck(
                  saveDatas[yIndex].jobs[xIndex].date,
                  availabilities
                )
                  ? 'bg-danger p-25 text-white'
                  : ''
              "
              v-if="role == 'Foreman'"
              >🤵🏻 {{ full_name }}</span
            >
            <span
              :class="
                selectDropDownCheck(
                  saveDatas[yIndex].jobs[xIndex].date,
                  availabilities
                )
                  ? 'bg-danger p-25 text-white'
                  : ''
              "
              v-else
              >🧍🏻 {{ full_name }}</span
            >

            <!-- <cite v-if="selectDropDownCheck(saveDatas[yIndex].jobs[xIndex].date, availabilities)" class="text-danger text-small">
              <br />
              Not Available
            </cite> -->
          </template>
        </v-select>
      </li>
    </td>

    <vue-context v-if="$Can('staff_modal_add_btn')" ref="staffMenuData">
      <li>
        <b-link
          v-for="data in staffMenuData"
          :key="data.text"
          class="d-flex align-items-center"
          @click="optionClicked(data.text, data)"
        >
          <feather-icon :icon="data.icon" size="12" />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

    <b-modal
      :id="modalGroupDates"
      :ref="modalGroupDates"
      cancel-variant="outline-secondary"
      ok-title="Save"
      @cancel="groupDatesHandleCancel"
      cancel-title="Cancel"
      :no-close-on-backdrop="true"
      centered
    >
      <template #modal-header="{ close }">
        <span>Select Staff Group Dates</span>
      </template>

      <b-form ref="groupDatesRules">
        <b-form-group
          :state="groupDatesState"
          invalid-feedback="Date field is required"
          label="Date*"
          label-for="date"
        >
          <flat-pickr
            id="date"
            :state="groupDatesState"
            input-id="date"
            required
            v-model="groupDates"
            class="form-control"
            :config="{
              mode: 'multiple',
              locale: { firstDayOfWeek: 1 },
              dateFormat: 'd/m/Y',
              enable: enabledGroupDates,
            }"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      v-if="$Can('staff_modal_add_btn')"
      id="modal-staff-set"
      ref="modalStaffSet"
      no-close-on-backdrop
      size="lg"
      centered
      ok-title="Save"
      cancel-variant="outline-secondary"
      @ok="staffHandleOk"
      @cancel="staffHandeCancel"
    >
      <template #modal-header="{ close }">
        <span>{{ "Event Date : " + dateFormat(modalSelectDate) }}</span>
      </template>

      <!-- Field: Team List -->
      <b-col cols="12">
        <b-row>
          <b-col cols="10">
            <b-form-group label="Staff Select" label-for="role">
              <v-select
                v-model="staffSelect"
                @input="staffExistsCheck"
                :options="filteredNewStaffList"
                :reduce="(val) => val"
                label="full_name"
                :clearable="false"
                input-id="role"
              >
                <template #option="{ full_name, availabilities, role }">
                  <span
                    :class="
                      selectDropDownCheck(modalSelectDate, availabilities)
                        ? 'bg-danger p-25 text-white'
                        : ''
                    "
                    v-if="role == 'Foreman'"
                    >🤵🏻 {{ full_name }}</span
                  >
                  <span
                    :class="
                      selectDropDownCheck(modalSelectDate, availabilities)
                        ? 'bg-danger p-25 text-white'
                        : ''
                    "
                    v-else
                    >🧍🏻 {{ full_name }}</span
                  >

                  <!-- <cite v-if="selectDropDownCheck(saveDatas[yIndex].jobs[xIndex].date, availabilities)" class="text-danger text-small">
              <br />
              Not Available
            </cite> -->
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col v-show="$Can('staff_modal_add_btn')" cols="2">
            <b-form-group class="mt-2">
              <b-button
                @click="staffAdd"
                :disabled="addButtonCheck"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <!-- Field: Team List -->

      <b-col cols="12">
        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :items="modalTeamList"
          filter-debounce="250"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(id)="data">
            {{ data.value }}
          </template>

          <template #cell(role)="data">
            {{ data.value }}
          </template>
          <template #cell(employeeName)="data">
            {{ data.item.name }} {{ data.item.surname }}
          </template>

          <template #cell(availability)="data">
            <b-badge
              v-if="
                staffAvailablityModal(
                  data.item.id,
                  modalSelectDate,
                  data.item.isNew
                ) == 0
              "
              pill
              :variant="`success`"
              class="text-capitalize mr-1"
            >
              Available
            </b-badge>

            <b-badge
              v-else-if="
                staffAvailablityModal(
                  data.item.id,
                  modalSelectDate,
                  data.item.isNew
                ) == 1
              "
              pill
              :variant="`light-danger`"
              class="text-capitalize mr-1"
            >
              Conflict
            </b-badge>

            <b-badge
              v-else-if="
                staffAvailablityModal(
                  data.item.id,
                  modalSelectDate,
                  data.item.isNew
                ) == 2
              "
              pill
              :variant="`light-warning`"
              class="text-capitalize mr-1"
            >
              Not Available
            </b-badge>
          </template>
          <template #cell(action)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon btn-sm"
              @click="staffDelete(data)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-modal>
  </tr>
</template>

<script>
import { VBToggle, VBModal } from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import homeStoreModule from "./homeStoreModule";
import vSelect from "vue-select";
import moment from "moment";
import VueContext from "vue-context";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import Cleave from "vue-cleave-component";

// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    vSelect,
    VueContext,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },

  data() {
    return {
      modalEventId: null,
      selectedLiIndex: {},
      selectedNewStaffId: null,
      staffMenuData: [],
      enabledGroupDates: [],
      enabledGroupIds: [],
      groupDates: null,
      staffSelect: null,
      addButtonCheck: false,
      modalTeamList: [],
      modalTeamListS: [],

      tableColumns: [
        { key: "id", sortable: false, class: "text-center" },
        { key: "role", sortable: false, class: "text-center" },
        {
          key: "employeeName",
          sortable: false,
          class: "text-center",
          thStyle: "width:150px",
        },
        {
          key: "availability",
          sortable: false,
          class: "text-center",
          thStyle: "width:50px",
        },
        { key: "action", sortable: false, class: "text-center" },
      ],
    };
  },

  props: {
    saveDatas: Array,
    xIndex: Number,
    formShow: Boolean,
    modalId: String,
    modalGroupDates: String,
    staffList: Array,
    indexColors: Array,
  },

  mounted() {},

  computed: {
    groupDatesState() {
      return this.groupDates != null && this.groupDates != "" ? true : false;
    },

    getRequired() {
      return (job) => {
        let result = job.employess_required - job.actual;
        if (result > 0) {
          return result;
        } else {
          return 0;
        }
      };
    },

    filteredStaffList() {
      if (!this.selectedLiIndex.eventId) {
        return this.staffList;
      }
      const currentJob = this.saveDatas
        .flatMap((day) => day.jobs)
        .find((job) => job.id === this.selectedLiIndex.eventId);
      const allDayJobs = this.saveDatas
        .map((day) => day.jobs)
        .flat(1)
        .filter((e) => e.date == this.selectedLiIndex.date);

      if (!currentJob) {
        return this.staffList;
      }
      const currentTeamListIds = currentJob.team_list.map((staff) => staff.id);

      const unavailable = [];
      allDayJobs.forEach((j) => {
        j.team_list.forEach((element) => {
          if (element.role == "Foreman") {
            unavailable.push(element.id);
          }
        });
      });

      for (let i = 0; i < this.staffList.length; i++) {
        const st = this.staffList[i];
        const availabilities = st.availabilities;
        let found = false;
        for (const key in availabilities) {
          const val = availabilities[key];
          if (
            val.availability == "H" ||
            val.availability == "S" ||
            val.availability == "W"
          ) {
            const date = this.dateFormatDMY(this.selectedLiIndex.date);
            if (val.new_date == date) {
              found = true;
              break;
            }
          }
        }
        if (found) {
          unavailable.push(st.id);
        }
      }
      const dayListFiltered = this.staffList.filter(
        (staff) =>
          !unavailable.includes(staff.id) &&
          (!currentTeamListIds.includes(staff.id) ||
            staff.id === this.selectedStaffId.id)
      );

      return dayListFiltered;
    },

    filteredNewStaffList() {
      if (!this.selectedLiIndex.eventId && !this.selectedNewStaffId) {
        return this.staffList;
      }

      const currentJob = this.saveDatas
        .flatMap((day) => day.jobs)
        .find((job) => job.id === this.selectedLiIndex.eventId);
      const allDayJobs = this.saveDatas
        .map((day) => day.jobs)
        .flat(1)
        .filter((e) => e.date == this.selectedLiIndex.date);

      const currentTeamListIds = currentJob
        ? currentJob.team_list.map((staff) => staff.id)
        : [];
      const unavailable = [];
      allDayJobs.forEach((j) => {
        j.team_list.forEach((element) => {
          if (element.role == "Foreman") {
            unavailable.push(element.id);
          }
        });
      });

      for (let i = 0; i < this.staffList.length; i++) {
        const st = this.staffList[i];
        const availabilities = st.availabilities;
        let found = false;
        for (const key in availabilities) {
          const val = availabilities[key];
          if (
            val.availability == "H" ||
            val.availability == "S" ||
            val.availability == "W"
          ) {
            const date = this.dateFormatDMY(this.selectedLiIndex.date);
            if (val.new_date == date) {
              found = true;
              break;
            }
          }
        }
        if (found) {
          unavailable.push(st.id);
        }
      }

      const dayListFiltered = this.staffList.filter(
        (staff) =>
          !unavailable.includes(staff.id) &&
          !currentTeamListIds.includes(staff.id)
      );
      return dayListFiltered;
    },

    staffAvailablity() {
      return (staffId, date) => {
        const selectStaff = this.staffList.find((e) => e.id == staffId);

        if (!selectStaff) {
          return 0;
        }

        const checkAvailblity = selectStaff.availabilities.filter(
          (e) => e.new_date === this.dateFormatDMY(date)
        );

        const isAvailable = checkAvailblity.some((e) => e.availability !== "E");

        if (isAvailable) {
          return 2;
        } else if (checkAvailblity.length > 1) {
          return 1;
        } else {
          return 0;
        }
      };
    },
  },

  methods: {
    selectDropDownCheck(date, availabilities) {
      return availabilities.some(
        (item) => item.new_date === this.dateFormatDMY(date)
      );
    },

    staffAvailablityModal(staffId, date, isNew) {
      const selectStaff = this.staffList.find((e) => e.id == staffId);

      if (!selectStaff) {
        return 0;
      }

      const checkAvailblity = selectStaff.availabilities.filter(
        (e) => e.new_date === this.dateFormatDMY(date)
      );

      const isAvailable = checkAvailblity.some((e) => e.availability !== "E");

      if (isNew) {
        if (isAvailable) {
          return 2;
        } else if (checkAvailblity.length >= 1) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (isAvailable) {
          return 2;
        } else if (checkAvailblity.length > 1) {
          return 1;
        } else {
          return 0;
        }
      }
    },

    handleStaffGroupUpdate() {
      const modalPromise = new Promise((resolve) => {
        this.$refs[this.modalId].$once("ok", () => {
          resolve();
        });
      });

      modalPromise.then(async () => {
        if (this.groupDates != null && this.groupDates != "") {
          this.$emit("update:formShow", true);
          let staffData = {
            eventId: this.modalEventId,
            teamList: this.modalTeamList,
            updateDates: this.groupDates.split(",").map((e) => {
              return this.dateFormatYMD(e);
            }),
          };

          let foremanCheck = await this.unavailableForemanList(
            staffData.updateDates,
            this.modalTeamList,
            this.enabledGroupIds
          );

          if (foremanCheck.length > 0) {
            let people = foremanCheck.map((e) => {
              return e.name + " " + e.surname;
            });
            let errorText =
              "Selected Foreman [" +
              people +
              "] is not available in one of the selected dates. Please change the foreman and try again.";
            this.$swal({
              title: "Availability Error",
              text: errorText,
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "OK",
            });

            this.$emit("update:formShow", true);
            this.$emit("get-select-week");
          } else {
            store
              .dispatch("home_planner/addStaffGroupList", staffData)
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successful",
                    text: "✔️ Staff List Update Successful",
                    icon: "ThumbsUpIcon",
                    variant: "success",
                  },
                });

                this.$emit("update:formShow", true);
                this.$emit("get-select-week");
              })
              .catch((e) => {
                console.log(e);
              });
          }
        } else {
          this.$emit("update:formShow", true);
          this.$emit("get-select-week");
        }
      });
    },

    unavailableForemanList(dates, tempList, eventIds) {
        return store.dispatch("home_planner/checkForemenList", {
          dates: dates,
          tempList: tempList,
          eventIds: eventIds,
        }).then((res) => {
          return res.data;
        }).catch((e) => {
          console.log(e);
          return [];
        });
    },

    staffDelete(data) {
      this.modalTeamList.splice(data.index, 1);
    },

    optionClicked(text, data) {
      if (text == "Edit") {
        this.staffClick(
          data.eventId,
          data.staffId,
          data.date,
          data.staffStatus,
          data.job
        );
      } else if (text == "Remove") {
        this.deleteStaff(data.deleteStaff, data.job);
      }
    },

    changeStaff(staffId, job) {
      this.$emit("update:formShow", true);
      if (staffId == this.selectedLiIndex.staffId) {
        this.selectedLiIndex = {};
        this.$emit("update:formShow", false);
      } else {
        this.addStaff(1, job);
      }
    },

    addStaff(where, job) {
      let eventId = job.id;
      let staffList = job.team_list;

      if (where == 1) {
        staffList.push({
          id: this.selectedStaffId.id,
          role: this.selectedStaffId.role,
          name: this.selectedStaffId.name,
          surname: this.selectedStaffId.surname,
        });
        staffList = staffList.filter(
          (e) => e.id != this.selectedLiIndex.staffId
        );
      } else if (where == 2) {
        staffList.push({
          id: this.selectedNewStaffId.id,
          role: this.selectedNewStaffId.role,
          name: this.selectedNewStaffId.name,
          surname: this.selectedNewStaffId.surname,
        });
      }

      this.$emit("update:formShow", true);

      var tempTeamList = staffList;

      tempTeamList.map((x) => {
        x.availabilities = [];
        return x;
      });

      this.selectedLiIndex = {};
      this.selectedNewStaffId = null;

      if (job.group_dates.length > 1) {
        this.$bvModal
          .msgBoxConfirm(
            "Would you like to update the staff list for all days of this event?",
            {
              title: "Please Confirm",
              size: "sm",
              okVariant: "primary",
              okTitle: "Yes",
              cancelTitle: "No",
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value != null) {
              if (value) {
                this.enabledGroupDates = [];

                this.enabledGroupDates = job.group_dates
                  .map((e) => {
                    // if (this.dateFormatYmdToYmd(e.date) != job.date) {
                    return e.date;
                    // }
                  })
                  .flat(1);

                this.groupDates = job.group_dates
                  .map((e) => {
                    return this.dateFormat(e.date);
                  })
                  .flat(1)
                  .join(", ");

                this.$bvModal.show(this.modalId);

                const modalPromise = new Promise((resolve) => {
                  this.$refs[this.modalId].$once("ok", () => {
                    resolve();
                  });
                });

                modalPromise.then(async() => {
                  let staffData = {
                    eventId: eventId,
                    teamList: tempTeamList,
                    updateDates: this.groupDates.split(",").map((e) => {
                      return this.dateFormatYMD(e);
                    }),
                  };

                  let foremanCheck = await this.unavailableForemanList(
                    staffData.updateDates,
                    tempTeamList,
                    job.group_ids
                  );

                  if (foremanCheck.length > 0) {
                    let people = foremanCheck.map((e) => {
                      return e.name + " " + e.surname;
                    });
                    let errorText =
                      "Selected Foreman [" +
                      people +
                      "] is not available in one of the selected dates. Please change the foreman and try again.";
                    this.$swal({
                      title: "Availability Error",
                      text: errorText,
                      icon: "error",
                      showCancelButton: false,
                      confirmButtonText: "OK",
                    });

                    this.$emit("update:formShow", true);
                    this.$emit("get-select-week");
                  } else {
                    store
                      .dispatch("home_planner/addStaffGroupList", staffData)
                      .then((res) => {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: "Successful",
                            text: "✔️ Staff List Update Successful",
                            icon: "ThumbsUpIcon",
                            variant: "success",
                          },
                        });
                      })
                      .catch((e) => {
                        console.log(e);
                      })
                      .finally(() => {
                        this.$emit("update:formShow", true);
                        this.$emit("get-select-week");
                      });
                  }
                });
              } else {
                let staffData = {
                  eventId: eventId,
                  teamList: tempTeamList,
                };

                store
                  .dispatch("home_planner/addStaffList", staffData)
                  .then((res) => {
                    staffData.teamList.forEach((element) => {
                      var selectStaff = this.staffList.find((e) => {
                        return e.id == element.id;
                      });

                      selectStaff.availabilities = res.data.find((x) => {
                        return x.id == element.id;
                      }).availabilities;
                    });

                    this.saveDatas.some((days) => {
                      const foundJob = days.jobs.find(
                        (job) => job.id == eventId
                      );

                      if (foundJob) {
                        foundJob.team_list = res.data;
                        foundJob.actual = res.data.length;
                        return true;
                      }

                      return false;
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    this.$emit("update:formShow", true);
                    this.$emit("get-select-week");
                  });
              }
            } else {
              this.$emit("get-select-week");
            }
          });
      } else {
        this.$emit("update:formShow", true);

        let staffData = {
          eventId: eventId,
          teamList: tempTeamList,
        };

        store
          .dispatch("home_planner/addStaffList", staffData)
          .then((res) => {
            staffData.teamList.forEach((element) => {
              var selectStaff = this.staffList.find((e) => {
                return e.id == element.id;
              });

              selectStaff.availabilities = res.data.find((x) => {
                return x.id == element.id;
              }).availabilities;
            });

            this.saveDatas.some((days) => {
              const foundJob = days.jobs.find((job) => job.id == eventId);

              if (foundJob) {
                foundJob.team_list = res.data;
                foundJob.actual = res.data.length;
                return true;
              }

              return false;
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("update:formShow", false);
          });
      }
    },

    async deleteStaff(staffId, job) {
      let eventId = job.id;
      let staffList = job.team_list;
      let newList = staffList.filter((e) => {
        return e.id != staffId;
      });

      var tempTeamList = newList;

      tempTeamList.map((x) => {
        x.availabilities = [];
        return x;
      });

      if (job.group_dates.length > 1) {
        this.$bvModal
          .msgBoxConfirm(
            "Would you like to update the staff list for all days of this event?",
            {
              title: "Please Confirm",
              size: "sm",
              okVariant: "primary",
              okTitle: "Yes",
              cancelTitle: "No",
              cancelVariant: "outline-secondary",

              hideHeaderClose: false,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value != null) {
              if (value) {
                this.enabledGroupDates = [];

                this.enabledGroupDates = job.group_dates
                  .map((e) => {
                    // if (this.dateFormatYmdToYmd(e.date) != job.date) {
                    return e.date;
                    // }
                  })
                  .flat(1);

                this.groupDates = job.group_dates
                  .map((e) => {
                    return this.dateFormat(e.date);
                  })
                  .flat(1)
                  .join(", ");

                // this.$bvModal.show(this.modalId);

                this.$bvModal.show(this.modalId);

                const modalPromise = new Promise((resolve) => {
                  this.$refs[this.modalId].$once("ok", () => {
                    resolve();
                  });
                });

                modalPromise.then(async() => {
                  let staffData = {
                    eventId: eventId,
                    teamList: tempTeamList,
                    updateDates: this.groupDates.split(",").map((e) => {
                      return this.dateFormatYMD(e);
                    }),
                  };

                  let foremanCheck = await this.unavailableForemanList(
                    staffData.updateDates,
                    tempTeamList,
                    job.group_ids
                  );

                  if (foremanCheck.length > 0) {
                    let people = foremanCheck.map((e) => {
                      return e.name + " " + e.surname;
                    });
                    let errorText =
                      "Selected Foreman [" +
                      people +
                      "] is not available in one of the selected dates. Please change the foreman and try again.";
                    this.$swal({
                      title: "Availability Error",
                      text: errorText,
                      icon: "error",
                      showCancelButton: false,
                      confirmButtonText: "OK",
                    });

                    this.$emit("update:formShow", true);
                    this.$emit("get-select-week");
                  } else {
                    store
                      .dispatch("home_planner/addStaffGroupList", staffData)
                      .then((res) => {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: "Successful",
                            text: "✔️ Staff List Update Successful",
                            icon: "ThumbsUpIcon",
                            variant: "success",
                          },
                        });
                      })
                      .catch((e) => {
                        console.log(e);
                      })
                      .finally(() => {
                        this.$emit("update:formShow", true);
                        this.$emit("get-select-week");

                        this.selectedLiIndex = {};
                        this.selectedNewStaffId = null;
                      });
                  }
                });
              } else {
                let staffData = {
                  eventId: eventId,
                  teamList: tempTeamList,
                };

                try {
                  try {
                    const res = await store.dispatch(
                      "home_planner/addStaffList",
                      staffData
                    );
                    this.$emit("get-select-week");
                  } catch (error) {
                    console.log(error);
                  }
                } finally {
                  this.$emit("update:formShow", false);
                  this.selectedLiIndex = {};
                  this.selectedNewStaffId = null;
                }
              }
            } else {
              this.$emit("get-select-week");
            }
          });
      } else {
        let staffData = {
          eventId: eventId,
          teamList: tempTeamList,
        };

        return store
          .dispatch("home_planner/addStaffList", staffData)
          .then((res) => {})
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("get-select-week");
            this.selectedLiIndex = {};
            this.selectedNewStaffId = null;
          });
      }
    },

    staffClick(eventId, staffId, date, staffStatus, job) {
      if (staffStatus == "A") {
        this.selectedStaffId = this.staffList.find((e) => e.id == staffId);
        this.selectedEventId = eventId;
        this.selectedLiIndex = { staffId, eventId, date };
        this.isClick = !this.isClick;
      } else {
        this.eventEmployeeListChange(job);
        //this.$refs.modalStaffSet.show();
      }
    },

    dateFormat(val) {
      moment.locale("en-US");
      return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    dateFormatYmdToYmd(val) {
      moment.locale("en-US");
      return moment(val, "YYYY-MM-DD").format("YYYY-MM-DD");
    },

    dateFormatDMY(val) {
      moment.locale("en-US");
      return moment(val, "YYYY-MM-DD").format("DD-MM-YYYY");
    },

    dateFormatYMD(val) {
      moment.locale("en-US");
      return moment(val, "DD/MM/YYYY").format("YYYY-MM-DD");
    },

    eventEmployeeListChange(job) {
      let val = job.team_list;
      let date = job.date;
      let id = job.id;
      let dataJobDatesCount = job.group_dates.length;

      if (this.$Can("staff_modal_add_btn")) {
        this.modalSelectDate = date;
        this.modalTeamList = val;
        this.modalTeamListS = JSON.parse(JSON.stringify(val));
        this.modalEventId = id;
        this.$refs.modalStaffSet.show();
        this.dataJobDatesCount = dataJobDatesCount;
        this.enabledGroupDates = [];
        this.enabledGroupIds = job.group_ids;

        this.enabledGroupDates = job.group_dates
          .map((e) => {
            // if (this.dateFormatYmdToYmd(e.date) != job.date) {
            return e.date;
            // }
          })
          .flat(1);

        this.groupDates = job.group_dates
          .map((e) => {
            return this.dateFormat(e.date);
          })
          .flat(1)
          .join(", ");

        this.oldSelectEventClear(id, date);
      }
    },

    handleStaffUpdate() {
      let staffData = {
        eventId: this.modalEventId,
        teamList: this.modalTeamList,
      };

      store
        .dispatch("home_planner/addStaffList", staffData)
        .then((res) => {
          // staffData.teamList.forEach((element) => {
          //   var selectStaff = this.staffList.find((e) => {
          //     return e.id == element.id;
          //   });

          //   selectStaff.availabilities = res.data.find((x) => {
          //     return x.id == element.id;
          //   }).availabilities;
          // });

          // this.saveDatas.some((days) => {
          //   const foundJob = days.jobs.find((job) => job.id == this.modalEventId);

          //   var tempTeamList = res.data;

          //   tempTeamList.map((x) => {
          //     x.availabilities = [];
          //     return x;
          //   });

          //   if (foundJob) {
          //     foundJob.team_list = tempTeamList;
          //     foundJob.actual = res.data.length;
          //     return true;
          //   }

          //   return false;
          // });

          this.$emit("update:formShow", true);
          this.$emit("get-select-week");

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successful",
              text: "✔️ Staff List Update Successful",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    oldSelectEventClear(eventId, date) {
      this.selectedLiIndex = {};
      this.selectedLiIndex.eventId = eventId;
      this.selectedLiIndex.date = date;
    },

    staffAdd() {
      this.modalTeamList.push({
        id: this.staffSelect.id,
        role: this.staffSelect.role,
        name: this.staffSelect.name,
        surname: this.staffSelect.surname,
        isNew: true,
      });

      this.staffSelect = null;
      //    this.eventData.teamListList = this.staffList;
    },

    handleClick(event, staffId, job, staffStatus) {
      this.staffMenuData = [
        {
          icon: "EditIcon",
          text: "Edit",
          staffId: staffId,
          date: job.date,
          eventId: job.id,
          staffStatus: staffStatus,
          job: job,
        },
        {
          icon: "XIcon",
          text: "Remove",
          deleteStaff: staffId,
          eventId: job.id,
          staffList: job.team_list,
          job: job,
        },
      ];

      this.$refs.staffMenuData.open(event, staffId, job.id);
    },

    staffHandleOk(bvModalEvt) {
      // bvModalEvt.preventDefault();

      if (this.$Can("staff_modal_add_btn")) {
        if (this.dataJobDatesCount > 1) {
          this.$bvModal
            .msgBoxConfirm(
              "Would you like to update the staff list of the other days of this event?",
              {
                title: "Please Confirm",
                size: "sm",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "outline-secondary",

                hideHeaderClose: false,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                centered: true,
              }
            )
            .then((value) => {
              if (value != null) {
                if (value) {
                  this.$bvModal.show(this.modalId);
                  this.handleStaffGroupUpdate();
                } else {
                  this.handleStaffUpdate();
                }
              } else {
                this.$emit("update:formShow", true);
                this.$emit("get-select-week");
              }
            });
        } else {
          this.handleStaffUpdate();
        }
      } else {
        bvModalEvt.preventDefault();

        this.$swal({
          title: "Authorization Error",
          text: "🛑 You are not authorized",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      }
    },

    staffExistsCheck(val) {
      this.addButtonCheck = false;
      this.modalTeamList.forEach((element) => {
        if (element.id == val.id) {
          this.addButtonCheck = true;
        }
      });
    },

    staffHandeCancel() {
      this.$emit("update:formShow", true);
      this.$emit("get-select-week");
    },

    groupDatesHandleCancel() {
      this.$bvModal.hide(this.modalId);
      this.$emit("update:formShow", true);
      this.$emit("get-select-week");
    },
  },
};
</script>

<style lang="scss" scoped>
#plannerCover {
  font-size: 0.9em;
  line-height: 1em;
}

#buttonsCard {
  // position: sticky;
  top: 130px;
}

.weekNumbers {
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  font-size: 0.85em;
  padding: 0.4em;
  height: 2em;
  width: 2em;
}

.allWeeks {
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  font-size: 0.85em;
  padding: 0.4em;
  height: 2em;
}

#weeksTwentyOne {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

#weeksTwentyOne::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#weeksTwentyOne::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

#weeksTwentyOne::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #133842;
}

td {
  min-width: 130px;
  text-align: center;
  vertical-align: middle;
  height: 20px;
  border-bottom: 0px groove #00000020;
}

th {
  position: sticky;
  left: 0;
  min-width: 150px;

  background-color: rgb(0, 64, 75);
  color: white;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 0px groove #00000020;
  padding-left: 5px;
  outline: 1px groove #00000020;
}

// :nth-child(-n + 5)
thead {
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  background-color: white;
  // border: 1px solid black;
}

tbody td {
  z-index: 9;
}

.leftTitle {
  z-index: 9;
  vertical-align: middle;
  border-bottom: 0px groove #00000020;
  padding-left: 5px;
  outline: 1px solid #00000020;
}

.plannerTd {
  z-index: 9;
  outline: 1px solid #00000020;
  padding-bottom: 5px;
  padding-top: 5px;
  vertical-align: middle;
  max-width: 150px;
}

.plannerTdStaff {
  z-index: 9;
  outline: 1px solid #00000020;
  padding-bottom: 0px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
}

li {
  list-style-type: none;
}

.plannerTdStaff .wwsuccess {
  padding: 0.25rem 0.5rem;
  text-align: center;
  box-shadow: 0 0 5px #82868b;
  white-space: nowrap;
  font-size: 0.8em;
}

.errorStaff {
  outline: 1px solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px red !important;
  background-color: rgba(255, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid black;
}

.warningStaff {
  outline: 1px solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px blue !important;
  background-color: rgba(255, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid black;
}

.select-size-sm .vs__search {
  height: 2px;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(120px);
}

.noteTextArea {
  background-color: #fdfd96;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  top: 0;
  width: 90%;
  word-wrap: break-word;
  // margin-left: 5px;
  // margin-right: 5px;
}

.noteTextArea::-webkit-scrollbar {
  width: 8px;
}

.noteTextArea::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #133842;
}

.wsuccess {
  background-color: #5cb85c;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.wwarning {
  background-color: #f0ad4e;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.wwsecondary {
  background-color: #6e6e6e;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.fakeBadge {
  display: inline-block;
  padding: 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin-right: 0.4em;
  margin-left: 0.4em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #0d272e;
  color: white;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~vue-context/dist/css/vue-context.css";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vue-treeselect__value-remove {
  display: none;
}

.b-overlay {
  inset: -30px !important;
}
</style>
