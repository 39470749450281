<template>
  <tr>
    <th class="leftTitle">Notes</th>
    <td class="plannerTd" :style="{ 'background-color': indexColors[yIndex] }" @contextmenu.prevent="handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)" v-for="(y, yIndex) in saveDatas.length" @dblclick="$emit('update:noteEditing', saveDatas[yIndex].jobs[xIndex].id)">
      <transition name="fade" mode="out-in" v-if="saveDatas[yIndex].jobs[xIndex].length != 0 && $Can('event_edit_btn')">
        <span class="noteTextArea" v-if="noteEditing !== saveDatas[yIndex].jobs[xIndex].id" :key="'short-' + saveDatas[yIndex].jobs[xIndex].id">
          {{ saveDatas[yIndex].jobs[xIndex].notes ? saveDatas[yIndex].jobs[xIndex].notes.slice(0, 35) + "..." : "" }}
        </span>
        <span v-else :key="'full-' + saveDatas[yIndex].jobs[xIndex].id" @dblclick="$emit('update:noteEditing', null)">
          <textarea type="text" class="noteTextArea" v-model="saveDatas[yIndex].jobs[xIndex].notes" rows="10" @change="noteChange(saveDatas[yIndex].jobs[xIndex].id, saveDatas[yIndex].jobs[xIndex].notes)" name=""
            id="">                                                                                                                                                                                                                                                                                                                                                     }} </textarea>
        </span>
      </transition>
    </td>

    <vue-context v-if="$Can('event_edit_btn') || $Can('event_information_tab')" ref="eventMenuData">
      <li>
        <b-link v-for="data in eventMenuData" :key="data.text" class="d-flex align-items-center" @click="optionClickedNote(data.text, data)">
          <feather-icon :icon="data.icon" size="12" />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

  </tr>
</template>

<script>

import {
  VBToggle, VBModal,
} from "bootstrap-vue";
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import homeStoreModule from './homeStoreModule';
import vSelect from "vue-select";
import moment from "moment";
import VueContext from "vue-context";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";


// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    vSelect,
    VueContext,
    ToastificationContent,
    flatPickr,
  },

  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },


  data() {
    return {
      eventMenuData: [],

    };
  },

  props: {

    saveDatas: Array,
    xIndex: Number,
    noteEditing: [String, Number],
    formShow: Boolean,
    x: Number,
    indexColors: Array


  },

  mounted() {




  },

  computed: {



  },

  methods: {


    noteChange(eventId, note) {
      // changeNote
      this.$emit('update:formShow', true);
      store
      .dispatch("home_planner/changeNote", {
          eventId: eventId,
          note: note,
        })
        .then((res) => {

        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$emit('update:formShow', false);

        });
    },


    handleClickEvent(event, job, x, y) {
      this.eventMenuData = [];
      if (this.$Can("event_edit_btn")) {
        this.eventMenuData.push(
          { icon: "ClipboardIcon", text: "Add Note", job: job, x: x, y: y },
        );
      }
      if (job.length != 0) {
        this.$refs.eventMenuData.open(event, job);
      }
    },


    optionClickedNote(text, data) {
      if (text == "Add Note") {
        this.$emit('update:noteEditing', data.job.id)
      }
    },


  }
};
</script>



<style  lang="scss" scoped>
#plannerCover {
  font-size: 0.9em;
  line-height: 1em;
}

#buttonsCard {
  // position: sticky;
  top: 130px;
}

.weekNumbers {
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  font-size: 0.85em;
  padding: 0.4em;
  height: 2em;
  width: 2em;
}

.allWeeks {
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  font-size: 0.85em;
  padding: 0.4em;
  height: 2em;
}

#weeksTwentyOne {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

#weeksTwentyOne::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#weeksTwentyOne::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

#weeksTwentyOne::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #133842;
}

td {
  min-width: 130px;
  text-align: center;
  vertical-align: middle;
  height: 20px;
  border-bottom: 0px groove #00000020;
}

th {
  position: sticky;
  left: 0;
  min-width: 150px;

  background-color: rgb(0, 64, 75);
  color: white;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 0px groove #00000020;
  padding-left: 5px;
  outline: 1px groove #00000020;
}

// :nth-child(-n + 5)
thead {
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  background-color: white;
  // border: 1px solid black;
}

tbody td {
  z-index: 9;
}

.leftTitle {
  z-index: 9;
  vertical-align: middle;
  border-bottom: 0px groove #00000020;
  padding-left: 5px;
  outline: 1px solid #00000020;
}

.plannerTd {
  z-index: 9;
  outline: 1px solid #00000020;
  padding-bottom: 5px;
  padding-top: 5px;
  vertical-align: middle;
  max-width: 150px;
}

.plannerTdStaff {
  z-index: 9;
  outline: 1px solid #00000020;
  padding-bottom: 0px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
}

li {
  list-style-type: none;
}

.plannerTdStaff .wwsuccess {
  padding: 0.25rem 0.5rem;
  text-align: center;
  box-shadow: 0 0 5px #82868b;
  white-space: nowrap;
  font-size: 0.8em;
}

.errorStaff {
  outline: 1px solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px red !important;
  background-color: rgba(255, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid black;
}

.warningStaff {
  outline: 1px solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px blue !important;
  background-color: rgba(255, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid black;
}

.select-size-sm .vs__search {
  height: 2px;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(120px);
}

.noteTextArea {
  background-color: #fdfd96;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  top: 0;
  width: 90%;
  word-wrap: break-word;
  // margin-left: 5px;
  // margin-right: 5px;
}

.noteTextArea::-webkit-scrollbar {
  width: 8px;
}

.noteTextArea::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #133842;
}

.wsuccess {
  background-color: #5cb85c;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.wwarning {
  background-color: #f0ad4e;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.wwsecondary {
  background-color: #6e6e6e;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.fakeBadge {
  display: inline-block;
  padding: 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin-right: 0.4em;
  margin-left: 0.4em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #0d272e;
  color: white;
}
</style>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~vue-context/dist/css/vue-context.css";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vue-treeselect__value-remove {
  display: none;
}

.b-overlay {
  inset: -30px !important;
}
</style>