<template>
  <div>
    <home-planner  ></home-planner>
    <!-- <home-horizontal v-if="horizontalOrVertical == 'Horizontal'" :horizontalOrVertical.sync="horizontalOrVertical"></home-horizontal>
    <home-vertical v-else :horizontalOrVertical.sync="horizontalOrVertical"></home-vertical> -->
  </div>
</template>

<script>
// import HomeHorizontal from './HomeHorizontal.vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import homeStoreModule from './homeStoreModule';
// import HomeVertical from './HomeVertical';
import HomePlanner from './HomePlanner';

// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
  
 
    HomePlanner
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = 'home';
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      horizontalOrVertical: localStorage.getItem('horizontalOrVertical') ?? 'Vertical',
    };
  },

  mounted() {},
};
</script>
